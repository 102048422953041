"use client";

import Link from "next/link";
import Image from "next/image";
import "./styles.css";
import { useSearchParams } from "next/navigation";
import { useLogout } from "../lib/api/useLogout";
import { useSeller } from "../lib/api/useSeller";

const Footer = () => {
  const { logout } = useLogout();
  const { seller } = useSeller();
  const route = process.browser ? window.location.pathname : "";

  // const searchParams = useSearchParams();
  // const affiliate = searchParams?.get("affiliate");
  // const source = searchParams?.get("source");
  // const referral = searchParams?.get("referral");

  // const params = "?affiliate=" + affiliate + "&source=" + source + "&referral=" + referral;

  const handleLogoutClick = async () => {
    await logout();
  };

  const sections = [
    {
      title: "PRODUCT",
      links: [
        { text: "Components", link: "/" },
        { text: "Dashboard", link: "/dashboard" },
        { text: "Feature requests", link: "https://discord.gg/hKbvHAHvvU" },
      ],
    },
    {
      title: "FEATURES",
      links: [
        { text: "Generate item specs", link: "/dashboard" },
        { text: "Bulk list", link: "/dashboard" },
        { text: "Price comparisons", link: "/dashboard" },
      ],
    },
    {
      title: "RESOURCES",
      links: [
        { text: "Blog", link: "/blog" },
        { text: "Support", link: "https://discord.gg/hKbvHAHvvU" },
        { text: "Community", link: "https://discord.gg/hKbvHAHvvU" },
      ],
    },
    {
      title: "COMPANY",
      links: [
        { text: "FAQs", link: "/#faq-section" },
        { text: "Careers", link: "info@sellraze.com" },
        { text: "About", link: "/about" },
      ],
    },
  ];
  // if (route !== "/tos" && route !== "/faq") {
  //   sections[0].links.push({ text: "Pricing", link: "/pricing" });
  // }

  return (
    <footer className="relative mx-12 mt-24 space-y-14 lg:mx-32">
      <div className="flex flex-wrap">
        <div className="flex w-full flex-col items-start justify-between lg:flex-row">
          <div className="mb-12 flex h-full w-full flex-row justify-between lg:flex-col">
            <Link href={"/"}>
              <Image src="/sellRazeLogo.png" height="30" width="128" alt="Sellraze Logo" />
            </Link>
            <div className="align-center flex flex-row space-x-4">
              <Link
                href="https://www.x.com/sellraze"
                target="_blank"
                rel="noopener noreferrer"
                className="opacity-70 transition-all duration-200 hover:opacity-100"
              >
                <Image src="/iconX.png" height="16" width="16" alt="X Icon" />
              </Link>
              <Link
                href="https://www.linkedin.com/company/sellraze"
                target="_blank"
                rel="noopener noreferrer"
                className="opacity-70 transition-all duration-200 hover:opacity-100"
              >
                <Image src="/iconLinkedin.png" height="16" width="16" alt="LinkedIn Icon" />
              </Link>
              <Link
                href="https://discord.gg/hKbvHAHvvU"
                target="_blank"
                rel="noopener noreferrer"
                className="opacity-70 transition-all duration-200 hover:opacity-100"
              >
                <Image src="/iconDiscord.png" height="16" width="16" alt="Discord Icon" />
              </Link>
              <Link
                href="https://www.instagram.com/sellraze"
                target="_blank"
                rel="noopener noreferrer"
                className="opacity-70 transition-all duration-200 hover:opacity-100"
              >
                <Image src="/iconInstagram.png" height="16" width="16" alt="Instagram Icon" />
              </Link>
            </div>
          </div>

          <div className="flex w-full flex-col gap-8 lg:flex-row lg:gap-20">
            <div className="grid w-full grid-cols-2 gap-8 lg:flex lg:flex-row lg:gap-20">
              {sections.map((section) => (
                <div className="flex min-w-[150px] flex-col space-y-[20px]" key={section.title}>
                  <p className="font-sfmono text-[16px] text-dark-32">{section.title}</p>
                  <div className="flex flex-col space-y-[10px]">
                    {section.links.map((link) => (
                      <Link
                        className="whitespace-nowrap font-sans text-base leading-normal text-neutral-950 text-opacity-70"
                        href={link.link}
                        key={`${link.link} + ${link.text}`}
                      >
                        {link.text}
                      </Link>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row flex-wrap items-center justify-between border-t py-5">
        <div className="inline-flex flex-col items-start justify-start gap-2.5 lg:flex-row">
          <div className="font-sans text-[11px] leading-none text-neutral-950 text-opacity-50">© SellRaze 2024.</div>
          <div className="flex flex-row gap-2.5">
            <Link
              className="cursor-pointer font-sans text-[11px] leading-none text-neutral-950 text-opacity-50"
              href="/tos"
            >
              Terms
            </Link>
            <Link
              className="cursor-pointer font-sans text-[11px] leading-none text-neutral-950 text-opacity-50"
              href="/privacy"
            >
              Privacy
            </Link>
          </div>
        </div>
        <div className="flex flex-row items-center space-x-4">
          <div className="font-sans text-[11px] leading-none text-neutral-950 text-opacity-50">Download mobile</div>
          <Link
            className="cursor-pointer font-sans leading-none opacity-50 transition-all duration-200 hover:opacity-100"
            href="https://apps.apple.com/us/app/sellraze-list-sell-earn/id6455042085?itsct=apps_box_badge&amp;itscg=30200"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src="/iconAppStore.png" height="16" width="16" alt="App Store Icon" />
          </Link>
          <Link
            className="cursor-pointer font-sans leading-none opacity-50 transition-all duration-200 hover:opacity-100"
            href="https://play.google.com/store/apps/details?id=com.sellraze.scanner&pcampaignid=web_share"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src="/iconGooglePlay.png" height="16" width="16" alt="Google Play Icon" />
          </Link>
        </div>
      </div>
      <Image
        src="/sellRazeMetallic.png"
        height="326"
        width="326"
        alt="Sellraze Metallic Logo"
        className="md:-right-32 absolute -right-12 bottom-0 -z-10 opacity-5 lg:-right-32"
      />
    </footer>
  );
};

export default Footer;
